import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-4" }
const _hoisted_4 = { class: "col-xl-4" }
const _hoisted_5 = { class: "row g-5 g-xl-8" }
const _hoisted_6 = { class: "col-xl-4" }
const _hoisted_7 = { class: "col-xl-4" }
const _hoisted_8 = { class: "col-xl-4" }
const _hoisted_9 = { class: "row g-5 g-xl-8" }
const _hoisted_10 = { class: "col-xl-4" }
const _hoisted_11 = { class: "col-xl-4" }
const _hoisted_12 = { class: "col-xl-4" }
const _hoisted_13 = { class: "row g-5 g-xl-8" }
const _hoisted_14 = { class: "col-xl-4" }
const _hoisted_15 = { class: "col-xl-4" }
const _hoisted_16 = { class: "col-xl-4" }
const _hoisted_17 = { class: "row g-5 g-xl-8" }
const _hoisted_18 = { class: "col-xl-4" }
const _hoisted_19 = { class: "col-xl-4" }
const _hoisted_20 = { class: "col-xl-4" }
const _hoisted_21 = { class: "row g-5 g-xl-8" }
const _hoisted_22 = { class: "col-xl-3" }
const _hoisted_23 = { class: "col-xl-3" }
const _hoisted_24 = { class: "col-xl-3" }
const _hoisted_25 = { class: "col-xl-3" }
const _hoisted_26 = { class: "row g-5 g-xl-8" }
const _hoisted_27 = { class: "col-xl-4" }
const _hoisted_28 = { class: "col-xl-4" }
const _hoisted_29 = { class: "col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsWidget1 = _resolveComponent("StatisticsWidget1")!
  const _component_StatisticsWidget2 = _resolveComponent("StatisticsWidget2")!
  const _component_StatisticsWidget3 = _resolveComponent("StatisticsWidget3")!
  const _component_StatisticsWidget4 = _resolveComponent("StatisticsWidget4")!
  const _component_StatisticsWidget5 = _resolveComponent("StatisticsWidget5")!
  const _component_StatisticsWidget6 = _resolveComponent("StatisticsWidget6")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StatisticsWidget1, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          background: "abstract-4.svg",
          title: "Meeting Schedule",
          time: "3:30PM - 4:20PM",
          description: "Create a headline that is informative<br/>\r\n      and will capture readers"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StatisticsWidget1, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          background: "abstract-2.svg",
          title: "Meeting Schedule",
          time: "03 May 2020",
          description: "Great blog posts don’t just happen Even the best bloggers need it"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_StatisticsWidget1, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          background: "abstract-1.svg",
          title: "UI Conference",
          time: "10AM Jan, 2021",
          description: "AirWays A Front-end solution for airlines build with ReactJS"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_StatisticsWidget2, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          avatar: "/media/svg/avatars/029-boy-11.svg",
          title: "Arthur Goldstain",
          description: "System & Software Architect"
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_StatisticsWidget2, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          avatar: "/media/svg/avatars/014-girl-7.svg",
          title: "Lisa Bold",
          description: "Marketing & Fanance Manager"
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_StatisticsWidget2, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          avatar: "/media/svg/avatars/004-boy-1.svg",
          title: "Nick Stone",
          description: "Customer Support Team"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_StatisticsWidget3, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          height: 150,
          color: "success",
          title: "Weekly Sales",
          description: "Your Weekly Sales Chart",
          change: "+100"
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_StatisticsWidget3, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          height: 150,
          color: "danger",
          title: "Authors Progress",
          description: "Marketplace Authors Chart",
          change: "-260"
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_StatisticsWidget3, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          height: 150,
          color: "primary",
          title: "Sales Progress",
          description: "Marketplace Sales Chart",
          change: "+180"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_StatisticsWidget4, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          height: 150,
          "svg-icon": "/media/icons/duotune/ecommerce/ecm002.svg",
          color: "info",
          description: "Sales Change",
          change: "+256"
        })
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_StatisticsWidget4, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          height: 150,
          "svg-icon": "/media/icons/duotune/general/gen025.svg",
          color: "success",
          description: "Weekly Income",
          change: "750$"
        }),
        _createTextVNode("-> ")
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_StatisticsWidget4, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          height: 150,
          "svg-icon": "/media/icons/duotune/finance/fin006.svg",
          color: "primary",
          description: "New Users",
          change: "+6.6K"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "/media/icons/duotune/ecommerce/ecm002.svg",
          color: "danger",
          "icon-color": "white",
          title: "Shopping Chart",
          description: "Lands, Houses"
        })
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "/media/icons/duotune/ecommerce/ecm008.svg",
          color: "primary",
          "icon-color": "white",
          title: "Appartments",
          description: "Flats, Shared Rooms, Duplex"
        })
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-5 mb-xl-8",
          "svg-icon": "/media/icons/duotune/graphs/gra005.svg",
          color: "success",
          "icon-color": "white",
          title: "Sales Stats",
          description: "50% Increased for FY20"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "/media/icons/duotune/general/gen032.svg",
          color: "white",
          "icon-color": "primary",
          title: "500M$",
          description: "SAP UI Progress"
        })
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "/media/icons/duotune/ecommerce/ecm008.svg",
          color: "dark",
          "icon-color": "white",
          title: "+3000",
          description: "New Customers"
        })
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "/media/icons/duotune/finance/fin006.svg",
          color: "warning",
          "icon-color": "white",
          title: "$50,000",
          description: "Milestone Reached"
        })
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_StatisticsWidget5, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "svg-icon": "/media/icons/duotune/graphs/gra007.svg",
          color: "info",
          "icon-color": "white",
          title: "$50,000",
          description: "Milestone Reached"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_StatisticsWidget6, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          color: "success",
          title: "Avarage",
          description: "Project Progress",
          progress: "50%"
        })
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_component_StatisticsWidget6, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          color: "warning",
          title: "48k Goal",
          description: "Company Finance",
          progress: "15%"
        })
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createVNode(_component_StatisticsWidget6, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          color: "primary",
          title: "400k Impressions",
          description: "Marketing Analysis",
          progress: "76%"
        })
      ])
    ])
  ], 64))
}